@media print {
  @page {
    size: 5cm 10cm; /* Set the page size */
    margin: 0; /* Remove margins */
  }

  body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .parent-container {
    display: block;
    width: 5cm;
    height: 10cm;
    box-sizing: border-box;
    page-break-inside: avoid;
    background-color: aqua !important;
  }

  .coupon {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    page-break-before: always;
  }

  .coupon svg {
    height: 4cm !important;
    width: 4cm !important;
  }

  .text-content {
    text-align: center;
    font-size: 20px; /* Larger font size */
    font-weight: 700;
  }

  .coupon-name,
  .coupon-product-name {
    display: block;
    font-size: 20px !important;
    margin: 0;
    padding: 0;
  }
}


.dialog-content-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
}

/* .dialog-content-flex::-webkit-scrollbar-thumb {
    background: #000;
  } */

.profile-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: 10px auto;
}
.kyc-img {
  width: 250px;
  height: 250px;

  border-radius: 10%;
  background-size: 100% 100%;
}

.details-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-left: 70px;
}

.details-column {
  width: 90%;
}

.details-column li {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 10px;
}

.details-column span {
  width: 45%;
}

.kyc-radio-group {
  display: flex;
}

.row {
  display: flex;
}

/* Sidebar */
.sidebar {
  background-color: #333;
  color: #fff;
  height: 100vh;
  width: 250px;
  overflow-y: auto;
  transition: transform 0.3s ease;
  transform: translateX(-250px); /* Initially hide the sidebar */
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  padding: 10px;
}

.sidebar a {
  color: #fff;
  text-decoration: none;
}

/* Toggle button */
.sidebar-toggle-btn {
  display: none; /* Initially hidden on big screens */
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 24px;
  cursor: pointer;
  z-index: 999;
}

/* Show toggle button only on mobile screens */
@media screen and (max-width: 768px) {
  .sidebar-toggle-btn {
    display: block;
  }

  /* Slide in the sidebar when toggled open */
  .sidebar-open .sidebar {
    transform: translateX(0);
  }
}
